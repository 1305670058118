<template>
  <b-link class="brand-logo" :to="{ name: 'site-home' }">
    <h2 class="ml-4">
      <b-img class="logo-size" :src="imgLogo" alt="Divvy" />
    </h2>
  </b-link>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      imgLogo: require('@/assets/images/logo/logo.png'),
    }
  },
}
</script>

<style>
.logo-size {
  width: 40px;
}
</style>
