<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <img-logo />
      <b-col lg="6" class="recover-password succeed-mod">
        <h2 class="recover-password__title">Senha redefinida com sucesso!</h2>
        <div id="btt">
          <b-button
            class="recover-password__button"
            type="submit"
            variant="primary"
            block
            @click="$router.push({ name: 'login' })">
            <feather-icon icon="CheckIcon" />
          </b-button>
        </div>

        <p class="text-center mt-2">
          <b-link
            :to="{ name: 'login' }"
            class="recover-password-back">
            Voltar
          </b-link>
        </p>
      </b-col>
      <background-card />
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BLink,
} from 'bootstrap-vue'
import BackgroundCard from './template/BackgroundCard.vue'
import ImgLogo from './template/ImgLogo.vue'

export default {
  components: {
    BCol,
    BRow,
    BackgroundCard,
    BButton,
    ImgLogo,
    BLink,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import './sass/_recover-password.scss';
a.recover-password-back {
  color: #000;
  font-weight: 500;
}
</style>
